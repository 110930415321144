<template>
    <main-confirm @confirm="$emit('closeModal')" @cancel="$emit('closeModal')" confirm-title="account.you_only_have_one_address" description="account.desciption_you_only_have_one_address" :oneOption="true">
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import MainConfirm from '../../../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../../../common/svg/iconWarning.vue'
export default {
    name: "AlertOneAddress",
    components: {MainConfirm, IconWarning },
}
</script>