<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" confirm-title="account.delete_address" description="account.are_you_sure_you_want_to_delete_this_address">
        <icon-delete-address :size="80"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../../common/components/modals/MainConfirm.vue';
import IconDeleteAddress from '../../../assets/svg/iconDeleteAddress.vue';

export default {
    name: "ConfirmLocation",
    components: {
        MainConfirm,
        IconDeleteAddress
    },
}
</script>