<template>
    <main-modal title="account.select_address" @closeModal="$emit('closeModal')" class="select-address" :overflowVisible="true">
        <div class="container-select-address">
            <p class="subtitle-15">
                {{$t(`account.${getDimensions ? 'choose_the_address_to_receive_your_purchase' : 'you_can_also_edit_or_add_a_new_one_in_the_address_manager'}`)}}.
            </p>
            <alert-warning style="padding: 2px 15px" :size="25" :show="showWarning" :title="`account.${getDimensions ? 'the_branch_does_not_have_delivery_from_this_branch' : 'we_are_sorry_at_the_current_address_you_have_selected'}`" 
            :showCounter="false" class="mb-0 mb-md-3"/>
            <div class="pt-md-0" :class="{'pt-3': showWarning}">
                <list-address :addresses="addresses" :selectAddress="true" @position="(data) => { positionTop = data }" @changeBranch="showSelectBranch = true"/>
            </div>
            <popup v-if="branchList" class="d-none d-xxl-block" :style="{top: `calc(${positionTop}px - ${addresses.length == 1 ? 59 : 21}%)`}">
                <shipments-available @changeBranch="showSelectBranch = true"/>
            </popup>
            <router-link :to="'/account/addresses'" class="d-flex justify-content-md-end justify-content-center mt-4">
                <main-button title="account.manage_addresses" @click="$emit('closeModal')"/>
            </router-link>
        </div>
        <select-branch v-if="showSelectBranch" @closeModal="showSelectBranch = false" />
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal'
import { mapGetters } from "vuex"
import ListAddress from './ListAddress.vue'
import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue'
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import SelectBranch from '../../../branches/components/SelectBranch.vue'
import ShipmentsAvailable from './modals/ShipmentsAvailable.vue'
import Popup from '../../../../common/components/popup/Popup.vue'

export default {
    name: "SelectAddress",
    components: {
        MainModal,
        ListAddress,
        AlertWarning,
        MainButton,
        SelectBranch,
        ShipmentsAvailable,
        Popup
    },
    data(){ 
        return {
            positionTop: null,
            showSelectBranch: false,
            branchList: false,
        }
    },
    computed: {
    ...mapGetters({
            addresses: "account/getAddresses",
            addressSelectedAvailable: "account/addressSelectedAvailable",
        }),
        getDimensions(){
            return window.innerWidth <= 576
        },
        showWarning(){
            return this.addressSelectedAvailable ? !this.addressSelectedAvailable.available : false
        }
    },
    watch:{
        'addressSelectedAvailable.id'(){
            if(!this.addressSelectedAvailable.available){
                setTimeout(() => {
                    this.branchList = true
                }, 1);
            }else{
                this.branchList = false
            }
        }
    },
    created(){
        if(!this.addressSelectedAvailable.available){
            setTimeout(() => {
                this.branchList = true
            }, 1);
        }
    }
}
</script>

<style scoped>
    .select-address {
        text-align: initial;
    }
    .container-select-address {
        max-width: 736px;
    }
    @media (max-width: 576px) {
        .alert::v-deep {
            padding-inline: 10px !important;
        }
        .list-address::v-deep > li:first-child {
            margin-top: 0px;
        }
    }
    .center-down-arrow::before {
        right: 0%;
        transform: translate(50%, 300%) rotate(44deg);
    }
</style>
