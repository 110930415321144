<template>
    <ul class="list-address p-0 m-0 mb-3" id="list-address" :class="[addresses.length > 4 ? 'pe-md-2 pe-0' : 'pe-0']">
        <li v-for="address in addresses" :key="address.id" @click="select(address)" :id="`address-${address.id}`" class="address position-relative" 
        :class="{selectOption: addressSelected.id == address.id}">
            <div class="row mx-0">
                <div class="d-flex col-sm-12" :class="[selectAddress ? 'col-md-7':'col-md-6']">
                    <div style="margin-left: -15px;" :class="{'mt-3':mobile}" >
                        <radio-button :checked="addressSelected.id == address.id" class="d-md-block me-2" style="margin-top: 4px;"/>
                    </div>
                    <!-- <radio-button :checked="addressSelected.id == address.id" style="margin-top: 2px;"/> -->
                    <div class="w-100">
                        <div class="d-flex d-md-inline justify-content-between">
                            <div class="d-flex d-md-inline text-limited">
                                <icon-star v-if="address.default" :size="15" class="d-flex d-md-none me-2" />
                                <span class="address-name subtitle-15 text-capitalize">{{address.name | textFormat}}</span>
                                <!-- <icon-check style="margin-left: 3px;" v-if="addressSelected.id == address.id" class="d-flex d-md-none me-2" :color="'var(--green-600)'" :size="15" /> -->
                            </div>
                            <span class="info-separator d-none d-md-inline-block mx-2 font-weight-light color-dark-gray-200">|</span>
                            <span v-if="checkoutSteps.addressOptionSelected == 2 ? false : !addressIsAvailable(address)" class="d-inline-flex d-md-none align-items-center subtitle-13 color-light-main-red">
                                <icon-warning :size="15" class="me-2"/> {{$t("account.out_of_range")}}
                            </span>
                        </div>
                        <span class="address-phone font-weight-normal subtitle-15 weight-300 no-wrap"> 
                            Tel: <b class="color-dark-gray-400 weight-300">{{address.phone | phoneFormat}}</b>
                        </span> 
                        <div class="responsive-description d-flex">
                            <span class="text-black subtitle-15 text-limited weight-300 text-capitalize">
                                <span v-if="address" class="text-black subtitle-15 weight-300">{{ address.address_line2 || address.address_line | textFormat }}</span>, 
                                {{ address.city.city | textFormat}}
                            </span>
                        </div>
                        <div class="subtitle-14 weight-300">
                            <span>Ref:</span> <span class="color-dark-gray-400">{{ address.reference | textFormat }}</span> 
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-md-end align-items-start col-12 mt-2 mt-sm-0 ps-md-0 position-option" :class="[selectAddress ? 'col-md-5':'col-md-6']">
                    <div v-if="address.default" class="d-none d-md-flex d-lg-flex align-items-center">
                        <icon-star :size="15" class="me-2"/> <span class="subtitle-13">{{$t("account.default")}}</span>
                    </div>
                    <span v-if="checkoutSteps.addressOptionSelected == 2 ? false : !addressIsAvailable(address)" class="subtitle-13 color-light-main-red d-none d-md-flex">
                        <icon-warning :size="15" class="me-2"/> {{$t("account.address_out_of_range")}}
                    </span>
                    <div class="adress-options">
                        <a href="#" v-if="!selectAddress" @click.prevent="editAddress(address)" class="subtitle-13 main-link">
                            {{ $t("account.edit_address") }}
                        </a>
                        <span v-if="!selectAddress" class="me-2 ms-2 color-dark-gray-200">|</span>
                        <a href="#" @click.prevent="deleteAddress(address)" class="subtitle-13 main-link hover-underline">{{$t("account.remove")}}</a>
                        <span v-if="!address.default" class="me-2 ms-2 color-dark-gray-200">|</span>
                        <a v-if="!address.default" href="#" @click.prevent="defaultAddress(address.id)" class="subtitle-13 main-link hover-underline">{{$t("account.default")}}</a>
                    </div>
                </div>
            </div>
            <popup v-if="addressSelected.id == address.id && !addressSelectedAvailable.available" class="d-none d-md-block d-xxl-none" :style="{top: `${100}%`, right: `${2}%`}">
                <shipments-available @changeBranch="$emit('changeBranch')"/>
            </popup>
        </li>
        <alert-one-address v-if="oneAddress" @closeModal="oneAddress = false"/>
        <confirm-delete-address v-if="showConfirmDelete" @closeModal="showConfirmDelete = false" @confirmDelete="deleteConfirmed"/>
    </ul>
</template>

<script>
import { mapGetters } from "vuex"
import Popup from '../../../../common/components/popup/Popup.vue'
import RadioButton from '../../../../common/components/radio/RadioButton.vue'
import IconStar from '../../../../common/svg/iconStar.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue'
import { addressesMixin } from '../../helpers/mixins/addresses-mixin'
import AlertOneAddress from './modals/AlertOneAddress.vue'
import ConfirmDeleteAddress from './modals/ConfirmDeleteAddress.vue'
import ShipmentsAvailable from './modals/ShipmentsAvailable.vue'
// import iconCheck from "../../../../common/svg/iconCheck.vue"

export default {
    components: { RadioButton, IconStar, IconWarning, ConfirmDeleteAddress, AlertOneAddress, ShipmentsAvailable, Popup},
    name: "ListAddress",
    props: {
        addresses: {
            type: Array,
            required: true
        },
        selectAddress: {
            type: Boolean,
            default:false
        }
    },
    computed:{
        ...mapGetters({
            addressSelected: "account/addressSelected",
            getAddressAvailableList: "account/getAddressAvailableList",
            branchSelected: "branches/branchSelected",
            allAddressCheckSelect: "account/allAddressCheckSelect",
            branches: "branches/branches",
            addressSelectedAvailable: "account/addressSelectedAvailable",
            mobile: "common/mobile",
            checkoutSteps: "checkout/checkoutSteps"
        }),
    },
    data() {
        return {
            showConfirmDelete: false,
            oneAddress: false
        }
    },
    methods: {
        //method
        getBranch(code){
            const branch = this.$options.filters.filterBranch(this.branches, code)
            return branch
        },
        getPosition(address){
            let element = document.getElementById(`address-${address.id}`)
            let bodyRect = document.body.getBoundingClientRect(),
                elemRect = element.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top;
                this.$emit('position', offset)
        },
        async select(address){
            this.$store.commit('account/RESET_CARRIER')
            this.$store.commit("checkout/RESET_PAYMENTS")
            if(address.id == this.addressSelected.id) return
            this.$store.dispatch('account/setAddress', address)
            
            setTimeout(() => { this.getPosition(address)}, 1);
        }
    },
    mixins: [addressesMixin],
    mounted(){
        setTimeout(() => { this.getPosition(this.addressSelected)}, 1)
    }

}
</script>

<style scoped>
    .list-address {
        list-style: none;
        overflow: auto;
        max-height: 400px;
    }
    .list-address > li:first-child { 
        border-top: 1px solid var(--light-gray-501);
    }
    .responsive-description {
        max-width: 300px
    }
    @media (max-width: 550px) {
        .responsive-description {
            max-width: 275px;
        }
    }
    .address {
        cursor: pointer;
        border-bottom: 1px solid var(--light-gray-501);
        padding: 2%;
        position: relative;
        transition: var(--transition-1);
    }
    .address:hover {
        background: #f4f7f952;
    }
    .selectOption {
        background: var(--light-gray-100) !important;
    }
    @media (max-width: 578px) {
        .list-address > .address {
            border-radius: 8px;
            border: 1px solid var(--light-gray-501);
            margin-top: 18px;
            padding: 15px;
        }
        .list-address > .address:nth-child(1) {
            margin-top: 8px !important;
        }
        .main-link {
            font-weight: 600;
        }
        .position-option{
            margin-left: 10px;
        }
    }
    .popup::before{
        top: 0;
        right: 17%;
        transform: translate(50%, -50%) rotate(315deg);
    }
</style>
