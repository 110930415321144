<template>
    <nav class="navbar navbar-light bg-main-navy">
        <div class="container">
            <div class="row mx-0 w-100 d-flex justify-content-between">
                <div @click.prevent="openModalAddress" class="d-flex col-6 col-md-3 col-lg-3 justify-content-start align-items-center h-100 location cursor-pointer px-0" style="max-width: 230px;">
                    <div class="me-2">
                        <icon-location :size="22" color="#fff" style="transform: translateX(-2px);"/>
                    </div>
                    <div class="red-underline">
                        <div class="d-flex flex-column text-limited send-location">
                            <span class="size-12 weight-400">{{ $t('layout.send_to') }}</span>
                            <span class="size-13 weight-700 text-limited text-capitalize" data-toggle="tooltip" data-placement="top" :title="addressSelected ? addressSelected.city.city : $t('layout.click_here')+'!'" >{{  addressSelected ? addressSelected.city.city : $t('layout.click_here')+"!" }}</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex col-6 col-md-3 col-lg-3 justify-content-end text-end h-100 branch cursor-pointer px-0" @click.prevent="selectBranch = true">
                    <div class="d-flex flex-column me-2 red-underline">
                        <span class="size-12 weight-400">{{$t("branch.branch")}}</span>  
                        <span v-if="branchSelected" class="size-12 text-capitalize">{{ branchSelected.description | textFormat}}</span>
                        <span v-else class="size-13">{{$t('layout.click_here')+"!" }}</span>
                    </div>
                    <div>
                        <icon-branch :size="25" color="#fff"/>
                    </div>
                </div>
            </div>
        </div>
        <select-address v-if="selectAddress" @closeModal="selectAddress = false"/>
        <select-branch v-if="selectBranch && branches.length != 1  || selectBranchModal && branches.length != 1" @closeModal="closeSelectBranch"/>
    </nav>
</template>

<script>
import { mapGetters } from "vuex"
import IconBranch from '../../../../common/svg/iconBranch.vue'
import IconLocation from '../../../../common/svg/iconLocation.vue'
import SelectAddress from '../../../account/components/addresses/SelectAddress.vue'
import SelectBranch from '../../../branches/components/SelectBranch.vue'

export default {
    name: 'SecondNavbar',
    components:{
        SelectAddress,
        SelectBranch,
        IconBranch,
        IconLocation
    },
    data(){
        return {
            selectBranch: false,
            selectAddress: false
        }
    },
    computed:{
        ...mapGetters({
            addressSelected: "account/addressSelected",
            branchSelected: "branches/branchSelected",
            getAddresses: "account/getAddresses",
            isLogged: "auth/isLogged",
            employee: 'auth/employee',
            selectBranchModal: 'branches/selectBranchModal',
            employeeActiveCode: 'auth/employeeActiveCode',
            valueFilters: "products/valueFilters",
            branches: "branches/branches"
        }),
    },
    methods:{
        openModalAddress(){
            if(this.employeeActiveCode){
                return
            }
            if(this.getAddresses.length && this.isLogged){
                this.selectAddress = true
            }else{
                this.$router.replace({path: "/account/addresses"})
            }
        },
        closeSelectBranch(){
            this.selectBranch = false
            this.$store.commit('branches/OPEN_SELECT_BRANCH_MODAL', false)
        }
    }
}
</script>

<style lang="scss" scoped>
    div, span {
        color: var(--light-gray-50) !important;
    }

    .location, .branch {
        display: inline-block; 

        font-weight: 700; 
        line-height: 14px;
        .red-underline {
            &:after {
                width: 100%;
                right: 1px;
                bottom: -6px;
            }
        }
        &:hover {
            .red-underline:after,
            .red-underline:after {
                transform: scaleX(1);
                transform-origin: center;
            }
        }
    }
    nav {
        margin-top: 59px;
        
        padding: 8px;
        padding-bottom: 10px;
    }
    .send-location{
        max-width: 120px;
    }
    @media (min-width : 576px) {
        .send-location{
            max-width: 170px;
        }
    }
    @media (max-width: 576px) {
        .container {
            padding-inline: 2px;
        }
    }
</style>