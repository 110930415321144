<template>
    <div class="p-4">
        <h5 class="title-14 mb-0">{{$t('account.available_shipments')}}.</h5>
        <div class="color-dark-gray-400 size-12 d-flex justify-content-between">
            <span style="width: 160px" class="color-dark-gray-400">
                {{$t('account.you_are_in')}} <span class="text-capitalize">{{branchSelected.description | textFormat}}</span> 
            </span> 
            <a href="#" class="ms-3 weight-600" @click.prevent="$emit('changeBranch')">{{$t('product.change_branch')}}</a>
        </div>
        <ul class="subtitle-13 check-branch-list p-0 mb-0 mt-2">
            <li v-for="item in allAddressCheckSelect" :key="item.branch" class="d-flex align-items-center">
                <icon-check v-if="item.available" style="transform: scale(1.2);" :color="'var(--green-600)'" :size="14" /> <!-- A bigger manual size affects the alignment on the branch name, that's why we added via transform -->
                <icon-close v-else :color="'var(--danger-700)'" :size="14" />
                <span :class="[branchSelected.codplace === item.branch ? 'weight-500' : 'weight-100']" class="ms-2 text-capitalize">
                    {{getBranch(item.branch).description | textFormat}}
                </span>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import IconCheck from '../../../../../common/svg/iconCheck.vue'
import iconClose from '../../../../../common/svg/iconClose.vue'
    export default {
        components: {
            iconClose, 
            IconCheck 
        },
        name: "ShipmentsAvailable",
        computed: {
            ...mapGetters({
                allAddressCheckSelect: "account/allAddressCheckSelect",
                branchSelected: "branches/branchSelected",
                addressSelectedAvailable: "account/addressSelectedAvailable",
                branches: "branches/branches",
            })
        },
        methods: {
            getBranch(code){
                const branch = this.$options.filters.filterBranch(this.branches, code);
                return branch;
            }
        },
    }
</script>

<style scoped>
    .check-branch-list {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        list-style: none;
    }
</style>
